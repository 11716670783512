const _import = file => () => import('@/views/' + file + '.vue');
const Layout = _import('layout/layout');

// 路由信息在此声明，应当包含所有的路由信息
export default [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      // 首页
      {
        path: '/home',
        component: _import('home/home')
      },

      // 联系我们
      {
        path: '/contact-us',
        component: _import('contact-us')
      },

      // banner指向的文章
      {
        path: '/article',
        component: _import('home/article')
      }
    ]
  },

  // 关于浩凯
  {
    path: '/company',
    component: Layout,
    redirect: '/company/profile',
    children: [
      {
        path: '/company/profile',
        component: _import('company/company-profile')
      },
      {
        path: '/company/culture',
        component: _import('company/company-culture')
      }
    ]
  },

  // 产品中心
  {
    path: '/product',
    component: Layout,
    redirect: '/product/product-center',
    children: [
      {
        path: '/product/product-center',
        component: _import('product/product-center')
      },
      {
        path: '/product/product-detail',
        component: _import('product/product-detail')
      }
    ]
  },

  // 新闻资讯
  {
    path: '/news',
    component: Layout,
    redirect: '/news/news-list',
    children: [
      {
        path: '/news/news-list',
        component: _import('news/news-list')
      },
      {
        path: '/news/news-detail',
        component: _import('news/news-detail')
      }
    ]
  },

  // 公司相册
  {
    path: '/photos',
    component: Layout,
    redirect: '/photos/photos',
    children: [
      {
        path: '/photos/photos',
        component: _import('photos/photos')
      }
    ]
  },
];
