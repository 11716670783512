const message = {};

// 页面布局框架
message.layout = {
  companyName: 'Hao Kai Filter',
  companyFullName: 'Zhejiang Haokai Filter Co., Ltd.',
  homePage: 'Home',
  productsCenter: 'Products',
  productDetails: 'Details',
  aboutUs: 'About us',
  aboutHaoKai: 'About',
  companyProfile: 'Company profile',
  companyCulture: 'Corporate culture',
  companyPhotos: 'Album',
  companyHonor: 'Honor',
  companyEnvironment: 'Plant Environment',
  news: 'News',
  companyNews: 'Company News',
  industryNews: 'Industry News',
  contactUs: 'Contact Us',
  address: 'Address',
  addressValue: 'No. 8, Park Road, Pingqiao Industrial Park, Tiantai County, Taizhou City, Zhejiang Province, China',
  contact: 'Contact',
  contactValue: 'Wu Kai',
  tel: 'Tel',
  telValue: '0576-83680968 83680996',
  cellTel: 'Cell Phone',
  cellTelValue: '15325583659',
  fax: 'Fax',
  faxValue: '0576-83680978 83680998',
  email: 'Email',
  emailValue: 'wukai@hkfilter.cn',
  zip: 'Zip Code',
  zipCode: '317203',
  search: 'Search Product',
  viewMore: 'More',
};

// 首页
message.home = {
  companyAbout: 'About Company',
  companyCulture: 'Company Culture',
  companyProfileContent: 'Zhejiang Hao Kai Filter Co., Ltd. is located in the "China filter cloth city" - Zhejiang Tiantai Pingqiao Town flower front park, the company\'s main products are high-temperature glass fiber filter bag, Pi84 filter bag, the United States Tars filter bag, PPS Filter bag, acrylic filter bag, fluorine beauty filter bag, polyester film filter bag, stainless steel fiber anti-static filter bag',
  companyCultureHtml: `
      <span class="block">Business philosophy:<br/>Heaven and earth and win each other together</span>
      <span class="block">Corporate purposes:<br/>Make a model of a tree</span>
      <span class="block">Talent Concept:<br/>Only use, only Germany reuse</span>
      <span class="block">corporate vision:<br/>To be worthy of respect and trust of environmental service providers</span>
    `,
  applicationArea: 'Application Area',
};

// 产品中心
message.products = {
  introduce: 'Introduce',
  allProduct: 'All Product'
};

// 新闻
message.news = {
  previousNews: 'previous',
  nextNews: 'next'
};

// 联系我们
message.contactUs = {
  name: 'Name',
  tel: 'Tel',
  email: 'Email',
  qq: 'QQ',
  content: 'Content',
  submit: 'Submit',
};

// 企业简介
message.companyProfile = {
  title: "Company profile",
  p1: "Zhejiang Hao Kai Filter Co., Ltd. is located in the \"China filter cloth city\" - Zhejiang Pingqiao Town flower front park, the company's main products are high-temperature glass fiber filter bag, Polyimide filter fabric, Aramid filter fabric, PPS Filter bag, acrylic filter bag, fluorine beauty filter bag, polyester film filter bag, stainless steel fiber anti-static filter bag, widely used in cement kiln kiln, steel, electricity, waste incineration, carbon black, chemical and other industries , Our products not only effective control of dust emissions, and long life, efficient cost-effective to win the respect of the industry and domestic and foreign customers trust.",
  p2: "We go deep into the market, visit customers, understand the actual situation of the use of the situation, tailored for customers to solve the problem of scientific solutions, and provide good technical support and after-sales service. The company sold out not only products, but also a sense of responsibility and service. We always stand in the customer's point of view to think about the problem, and actively for the customer needs a good solution, which is my enterprise product development of the ultimate goal, but also Hao Kai company different from other business differences, in order to seek to open up independence Featured product road.",
  p3: "Since the establishment of the company, has always insisted on R & D to guide the market, to determine the production of sales, to serve as the fundamental purpose of the product concept. Over the years, our products have always served the forefront of the industry enterprises, the majority of customers establish a good reputation.",
  p4: "Hao Kay people to wise and enterprising, embrace the concept of change to manage the business, with a sincere attitude of good faith to make friends, with the technology to perfection of products to win the market, with fearless innovation to guide enterprises to move forward. We will through their own efforts to high-quality products and services to return"
};

// 企业文化
message.companyCulture = {
  title: "Corporate culture",
  p11: "Business philosophy:",
  p12: "Heaven and earth and win each other together",
  p21: "Corporate purposes:",
  p22: "Make a model of a tree",
  p31: "Talent Concept:",
  p32: "Only use, only Germany reuse",
  p41: "Corporate vision:",
  p42: "To be worthy of respect and trust of environmental service providers"
};

export default message;
