<template>
  <div id="app">
    <router-view/>
    <el-backtop target="#app"></el-backtop>
  </div>
</template>

<script>
  import '@/assets/styles/reset.scss';
  import '@/assets/styles/animation.scss';
  import '@/libs/colorui/colorui.css';


  export default {
    name: 'App'
  }
</script>

<style>
  #app {
    overflow-y: auto;
    position: relative;
  }
</style>
