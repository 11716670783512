const Image = {
  "static" : {
    "u168.jpg": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u168.jpg",
    /**
     * 转了之后为 "https://common-1300416852.picsh.myqcloud.com/haokailvye/static/u168.jpg?imageMogr2/format/webp"
     */
    "u169.jpg": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u169.jpg",
    "u170.jpg": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u170.jpg",
    "u171.jpg": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u171.jpg",
    "u773.jpg": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u773.jpg",
    "u775.jpg": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u775.jpg",
    "u776.jpg": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u776.jpg",
    "u88.png": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u88.png",
    "u90.png": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u90.png",
    "u92.png": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u92.png",
  }
};

const Video = {
  "static": {
    "u171.mp4": "https://common-1300416852.cos.ap-shanghai.myqcloud.com/haokailvye/static/u171.mp4"
  }
};

function transformOssToPicsh(ossUrl) {
  // 目前buket在上海
  return ossUrl.replace("cos.ap-shanghai", "picsh");
}

const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
const supportWebp = !![].map && document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0;

// 将图片按比例缩放
function imageMogrWidth(ossUrl, widthPx) {
  let picsh = transformOssToPicsh(ossUrl) + "?imageMogr2/thumbnail/" + widthPx + "x";
  return picsh;
}

// 将图片转为webp
function imageMogrWebp(ossUrl) {
  let picsh = transformOssToPicsh(ossUrl) + "?imageMogr2/format/webp";
  return picsh;
}

function autoTransform(baseUrl) {
  return supportWebp && baseUrl ? imageMogrWebp(baseUrl) : baseUrl;
}

export default {
  Image,
  Video,

  transformOssToPicsh,
  imageMogrWidth,
  imageMogrWebp,
  autoTransform
}
