

import Cache from './cache';

// 初始化一个缓存
const GlobalCache = new Cache({
  name: 'hk-web'
});
// TODO 在这里定义默认的key提供者

export default {
  install: function (Vue) {
    Vue.prototype.$cache = GlobalCache;
  }
};