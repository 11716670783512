import Router from 'vue-router'
import Routes from './RouteData'

// 创建一个全局唯一的Router实例
// 路由使用history模式后，在切换到子路由后，刷新页面会返回404
// 因为这时候uri是子路由，不是根路径 / ，就不会定向到打包后的index.html
// 因此也就无法初始化Vue，这时候需要添加一个nginx的配置，让所有的请求在nginx都转发到index.html
/*
  location / {
	    # 前端全部路由跳转到根目录，为了使history路由模式下，刷新不报404
	    proxy_pass http://127.0.0.1:8002/index.html;
	}

	# 最近router官方给出了一个解决方法
	location / {
    try_files $uri $uri/ /index.html;
  }
 */
const RouterInstance = new Router({
  mode: 'history',
  routes: Routes
});


/*
 * 单页面情况下，两个路由跳转之后，scrollTop也被保留，导致可能到新的页面后没有在顶部，此处做记录
 */
const ScrollTopCache = {};
RouterInstance.beforeEach((to, from, next) => {
  // 初始化新路由的scrollTop
  if (ScrollTopCache[to.fullPath] == null) {
    ScrollTopCache[to.fullPath] = 0;
  }
  // 记录上一个路由的scrollTop
  if (from) {
    // warn 这里的document.getElementById('app')不能缓存，缓存下来的取scrollTop有误
    const $app = document.getElementById('app');
    ScrollTopCache[from.fullPath] = $app.scrollTop;
  }
  next();
});
RouterInstance.afterEach((to, from) => {
  // 进入新路由之后，要滚动到记录的位置，需要在页面渲染完成后滚动，这里先不优雅的在100毫秒后执行
  setTimeout(() => {
    const $app = document.getElementById('app');
    $app.scrollTo(0, ScrollTopCache[to.fullPath]);
  }, 100);
});

const RouterInstaller = {
  install: function (Vue) {
    Vue.use(Router);
  },

  createRouter: function () {
    return RouterInstance;
  }
};

export default RouterInstaller;
