const message = {};

// 页面布局框架
message.layout = {
  companyName: '浙江浩凯滤业股份有限公司',
  companyFullName: '浙江浩凯滤业股份有限公司',
  homePage: '首页',
  productsCenter: '产品中心',
  productDetails: '查看详情',
  aboutUs: '关于我们',
  aboutHaoKai: '关于浩凯',
  companyProfile: '公司简介',
  companyCulture: '企业文化',
  companyPhotos: '公司相册',
  companyHonor: '企业荣誉',
  companyEnvironment: '园区环境',
  news: '新闻资讯',
  companyNews: '公司要闻',
  industryNews: '行业资讯',
  contactUs: '联系我们',
  address: '地址',
  addressValue: '浙江省台州市天台县平桥镇花前工业区园北路8号',
  contact: '联系人',
  contactValue: '吴凯',
  tel: '电话',
  telValue: '0576-83680968 83680996',
  cellTel: '手机',
  cellTelValue: '15325583659',
  fax: '传真',
  faxValue: '0576-83680978 83680998',
  email: '邮箱',
  emailValue: 'wukai@hkfilter.cn',
  zip: '邮编',
  zipCode: '317203',
  search: '搜索产品',
  viewMore: '查看更多',
};

// 首页
message.home = {
  companyAbout: '公司相关',
  companyCulture: '企业文化',
  companyProfileContent: '浙江浩凯滤业股份有限公司坐落于“中国过滤布名城”--浙江天台平桥镇花前园区，公司主要产品有高温玻纤覆膜滤袋、聚酰亚胺纤维滤料滤料、芳纶滤料、PPS滤袋、亚克力滤袋、氟美斯滤袋、涤纶覆膜滤袋、不锈钢纤维防静电滤袋等',
  companyCultureHtml: `
      <span class="block">经营理念：天地人和  互赢共进</span>
      <span class="block">企业宗旨：做一个工程  树一个样板</span>
      <span class="block">人才理念：唯才是用，唯德重用</span>
      <span class="block">企业愿景：做值得尊重和信赖的环保服务商</span>
    `,
  applicationArea: '应用领域',
};

// 产品中心
message.products = {
  introduce: '产品介绍',
  allProduct: '全部产品'
};

// 新闻
message.news = {
  previousNews: '上一篇',
  nextNews: '下一篇'
};

// 联系我们
message.contactUs = {
  name: '姓名',
  tel: '电话',
  email: '邮箱',
  qq: 'QQ',
  content: '留言内容',
  submit: '提交留言',
};

// 公司简介
message.companyProfile = {
  title: "公司简介",
  p1: "浙江浩凯滤业股份有限公司坐落于“中国过滤布名城”--浙江天台平桥镇花前园区，公司主要产品有高温玻纤覆膜滤袋、聚酰亚胺纤维滤料滤料、芳纶滤料、PPS滤袋、亚克力滤袋、氟美斯滤袋、涤纶覆膜滤袋、不锈钢纤维防静电滤袋等，广泛应用于水泥的窑头窑尾、钢铁、电力、垃圾焚烧、炭黑、化工等行业，我们的产品不仅有效的控制粉尘排放，而且使用寿命长，高效的性价比赢得了同行业的尊重和国内外客户的信赖。",
  p2: "我们深入市场，走访客户，了解工况实际的使用情况，为客户量身打造出科学的问题解决方案，并且提供良好的技术支持和售后服务。公司销售出去的不仅仅是产品，更是一种责任心和服务。我们始终站在客户的角度来思考问题，积极为客户需求良好的解决方案，这是我企业产品发展的终极目标，同时也是浩凯公司区别于其他企业的不同之处，以此寻求开辟出独具特色的产品道路。",
  p3: "公司自成立以来，一直坚持以研发引导市场，以销售决定生产，以服务为根本宗旨的产品理念。历年来，我们的产品始终服务于各行业企业的前沿，在广大顾客中树立优良的口碑。",
  p4: "浩凯人以睿智进取、拥抱变化的理念来管理企业，用真诚善良的心态广交朋友，用技臻至善的产品来赢得市场，以无所畏惧的创新精神引导企业向前发展。我们会通过自身的努力，以优质的产品和服务来回报社会，为我国的环保事业，添砖加瓦，尽一份力！"
};

// 企业文化
message.companyCulture = {
  title: "企业文化",
  p11: "经营理念",
  p12: "天地人和 互赢共进",
  p21: "企业宗旨",
  p22: "做一个工程 树一个样板",
  p31: "人才理念",
  p32: "唯才是用 唯德重用",
  p41: "企业愿景",
  p42: "做值得尊重和信赖的环保服务商"
};

export default message;
