import VueI18n from 'vue-i18n';

import CN from '../../assets/i18n/zh-CN';
import US from '../../assets/i18n/en-US';

const installer = {
    install: function (Vue) {
        Vue.use(VueI18n);
    },

    createI18n: function () {
        let lang = navigator.language;//获取浏览器配置语言，支持非IE浏览器
        try {
            lang = lang.substr(0, 2);//获取浏览器配置语言前两位
            if (lang === "zh") {
                lang = 'zh-CN';
            } else {
                lang = 'en-US';
            }
        } catch (e) {
            lang = 'zh-CN';
        }
        return new VueI18n({
            locale: lang,
            messages: {
                'zh-CN': CN,
                'en-US': US,
            }
        });
    }
};

export default installer;
