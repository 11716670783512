const Message = ELEMENT.Message;
const MessageBox = ELEMENT.MessageBox;

const request = axios.create({
  baseURL: "/api/web", // api的base_url 配置后，请求会拼接对应uri
  timeout: 120000 // request timeout
});

// 响应拦截器
request.interceptors.response.use(response => {// 对于200请求，弹出提示
  // 认证相关错误
  if (response.data.code !== 100) {
    Message.error(response.data.msg);
  }
  response.data.originResponse = response;
  return response.data
},  error => {// 对于网络错误，弹出提示
  console.log('err' + error);// for debug
  MessageBox({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error)
});

const installer = {
  install: function (Vue) {
    Vue.prototype.$ajax = request;
    window.$ajax = request;
  }
};

export default installer;